import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

let Home = () => import('../views/Home.vue')
let ChooseLang = () => import('../views/ChooseLang.vue')
let Settings = () => import('../views/Settings.vue')
let Introduction = () => import('../views/Introduction.vue')

let Pricing = () => import('../views/Pricing.vue')
let PricingPlan = () => import('../components/price/PricingPlan.vue')
let PricingOrder = () => import('../components/price/PricingOrder.vue')
let PricingResult = () => import('../components/price/PricingResult.vue')

let AITutor = () => import('../views/AITutor.vue')
let AITutorHistory = () => import('../views/AITutorHistory.vue')
let AITutorLesson = () => import('../views/AITutorLesson.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/start',
    name: 'Start',
    component: ChooseLang
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction
  },
  {
    path: '/pricing',
    component: Pricing,
    redirect: { name: 'PricingPlan' },
    children: [
      {
        path: 'plan',
        name: 'PricingPlan',
        component: PricingPlan
      },
      {
        path: 'order',
        name: 'PricingOrder',
        component: PricingOrder,
        props: true
      },
      {
        path: 'result',
        name: 'PricingResult',
        component: PricingResult,
        props: true
      }
    ]
  },
  {
    path: '/ai-tutor',
    name: 'AITutor',
    component: AITutor
  },
  {
    path: '/ai-tutor/lesson',
    name: 'AITutorLesson',
    component: AITutorLesson,
    props: true
  },
  {
    path: '/ai-tutor/history',
    name: 'AITutorHistory',
    component: AITutorHistory
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from ,next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
