const domain = 'https://www.speakey.com'
const apiUrl = 'https://webapi.speakey.com'

export default {
  // constants
  domain,
  firebaseConfig: {
    apiKey: "AIzaSyDRtLlqRAM403ps1FofIF8rTykwGaf5fVg",
    authDomain: "speakey-project.firebaseapp.com",
    projectId: "speakey-project",
    storageBucket: "speakey-project.appspot.com",
    messagingSenderId: "63456515790",
    appId: "1:63456515790:web:da31f683648b76f03aa29f",
    measurementId: "G-VX0R6MPM6B"
  },

  // langs
  baseLangs: ["en", "cn", "jp", "kr", "es", "fr", "de", "pt", "it", "vt", "ru", "id", "thai"],
  langsFullNameMap: {
    "en": {
      "cn": "Chinese",
      "tch": "Traditional Chinese",
      "jp": "Japanese",
      "kr": "Korean",
      "es": "Spanish",
      "fr": "French",
      "de": "German",
      "vt": "Vietnamese",
      "pt": "Portuguese",
      "en": "English",
      "it": "Italian",
      "ru": "Russian",
      "id": "Indonesian",
      "tu": "Turkish",
      "pl": "Polish",
      "thai": "Thai"
    },
    "cn": {
      "cn": "繁體中文",
      "tch": "繁體中文",
      "jp": "日語",
      "kr": "韓語",
      "es": "西班牙語",
      "fr": "法語",
      "de": "德語",
      "vt": "越語",
      "pt": "葡萄牙語",
      "en": "英語",
      "it": "意大利語",
      "ru": "俄語",
      "id": "印尼語",
      "tu": "土耳其語",
      "pl": "波蘭語"
    },
    "jp": {
      "cn": "中国語",
      "tch": "中国語",
      "jp": "日本語",
      "kr": "韓国語",
      "es": "スペイン語",
      "fr": "フランス語",
      "de": "ドイツ語",
      "vt": "ベトナム語",
      "pt": "ポルトガル語",
      "en": "英語",
      "it": "イタリア語",
      "ru": "ロシア語",
      "id": "インドネシア語",
      "tu": "トルコ語",
      "pl": "ポーランド語"
    },
    "kr": {
      "cn": "중국어",
      "tch": "중국어-번체",
      "jp": "일본어",
      "kr": "한국어",
      "en": "영어",
      "fr": "프랑스어",
      "de": "독일어",
      "es": "스페인어",
      "ru": "러시아어",
      "vt": "베트남어",
      "pt": "포르투갈어"
    },
    "es": {
      "cn": "chino",
      "tch": "chino tradicional",
      "jp": "japonés",
      "kr": "coreano",
      "es": "Español",
      "fr": "francés",
      "de": "alemán",
      "vt": "vietnamita",
      "pt": "portugués",
      "en": "inglés",
      "it": "italiano",
      "ru": "ruso",
      "id": "indonesio",
      "tu": "turco",
      "pl": "polaco"
    },
    "fr": {
      "cn": "chinois",
      "tch": "chinois traditionnel",
      "jp": "japonais",
      "kr": "coréen",
      "es": "espagnol",
      "fr": "Français",
      "de": "allemand",
      "vt": "vietnamien",
      "pt": "portugais",
      "en": "anglais",
      "it": "italien",
      "ru": "russe",
      "id": "indonésien",
      "tu": "turc",
      "pl": "polonais"
    },
    "de": {
      "cn": "Chinesisch",
      "tch": "Traditionelles Chinesisch",
      "jp": "Japanisch",
      "kr": "Koreanisch",
      "en": "Englisch",
      "fr": "Französisch",
      "de": "Deutsch",
      "es": "Spanisch",
      "ru": "Russisch",
      "vt": "Vietnamesisch",
      "pt": "Portugiesisch"
    },
    "pt": {
      "cn": "Chinês",
      "tch": "Chinês Tradicional",
      "jp": "Japonês",
      "kr": "Coreano",
      "es": "Espanhol",
      "fr": "Francês",
      "de": "Alemão",
      "vt": "Vietnamita",
      "pt": "Português",
      "en": "Inglês",
      "it": "Italiano",
      "ru": "Russo",
      "id": "Indonésio",
      "tu": "Turco",
      "pl": "Polaco"
    },
    "vt": {
      "cn": "Tiếng Trung",
      "tch": "Tiếng Trung phồn thể",
      "jp": "Tiếng Nhật",
      "kr": "Tiếng Hàn",
      "es": "Tiếng Tây Ban Nha",
      "fr": "Tiếng Pháp",
      "de": "Tiếng Đức",
      "vt": "Tiếng Việt",
      "pt": "Tiếng Bồ Đào Nha",
      "en": "Tiếng Anh",
      "it": "tiếng Ý",
      "ru": "tiếng Nga",
      "id": "tiếng In-đô-nê-xi-a",
      "tu": "tiếng Thổ Nhĩ Kỳ",
      "pl": "tiếng Ba Lan"
    },
    "ru": {
      "cn": "Китайский (упр.)",
      "tc": "Китайский (трад.)",
      "jp": "Японский",
      "kr": "Корейский",
      "es": "Испанский",
      "fr": "Французский",
      "de": "Немецкий",
      "vt": "Вьетнамский",
      "pt": "Португальский",
      "en": "Английский",
      "it": "Итальянский",
      "ru": "Русский",
      "id": "Индонезийский",
      "tu": "Турецкий",
      "pl": "Польский"
    },
    "it": {
      "cn": "Cinese",
      "tch": "Cinese Tradizionale",
      "jp": "Giapponese",
      "kr": "Coreano",
      "es": "Spagnolo",
      "fr": "Francese",
      "de": "Tedesco",
      "vt": "Vietnamita",
      "pt": "Portoghese",
      "en": "Inglese",
      "it": "Italiano",
      "ru": "Russo",
      "id": "Indonesiano",
      "tu": "Turco",
      "pl": "Polacco"
    },
    "id": {
      "cn": "Mandarin",
      "tch": "Mandarin Tradisional",
      "jp": "Bahasa Jepang",
      "kr": "Bahasa Korea",
      "es": "Bahasa Spanyol",
      "fr": "Bahasa Perancis",
      "de": "Bahasa Jerman",
      "vt": "Bahasa Vietnam",
      "pt": "Bahasa Portugis",
      "en": "Bahasa Inggris",
      "it": "Bahasa Itali",
      "ru": "Bahasa Rusia",
      "id": "Bahasa Indonesia",
      "tu": "Bahasa Turki",
      "pl": "Bahasa Polandia"
    },
    "thai": {
      "cn": "ภาษาจีน",
      "tch": "ภาษาจีนดั้งเดิม",
      "jp": "ภาษาญี่ปุ่น",
      "kr": "ภาษาเกาหลี",
      "es": "ภาษาสเปน",
      "fr": "ภาษาฝรั่งเศส",
      "de": "ภาษาเยอรมัน",
      "vt": "ภาษาเวียดนาม",
      "pt": "ภาษาโปรตุเกส",
      "en": "ภาษาอังกฤษ",
      "it": "ภาษาอิตาลี",
      "ru": "ภาษารัสเซีย",
      "id": "ภาษาอินโดนีเซีย",
      "tu": "ภาษาตุรกี",
      "pl": "ภาษาโปแลนด์",
      "thai": "ไทย"
    }
  },
  i18nMap: {
    "en": "en",
    "zh": "cn",
    "zh-CN": "cn",
    "zh-TW": "cn",
    "ja": "jp",
    "ko": "kr",
    "es": "es",
    "fr": "fr",
    "de": "de",
    "pt": "pt",
    "it": "it",
    "vi": "vt",
    "ru": "ru",
    "id": "id",
    "th": "thai"
  },
  htmlLangMap: {
    "en": "en",
    "cn": "zh",
    "jp": "ja",
    "kr": "ko",
    "es": "es",
    "fr": "fr",
    "de": "de",
    "pt": "pt",
    "it": "it",
    "vt": "vi",
    "ru": "ru",
    "id": "id",
    "thai": "th"
  },
  langsKeyMap: {
    "en": "en",
    "cn": "tch",
    "jp": "jp",
    "kr": "kr",
    "es": "es",
    "fr": "fr",
    "de": "de",
    "pt": "pt",
    "it": "ita",
    "vt": "vt",
    "ru": "ru",
    "id": "idn",
    "thai": "thai"
  },

  // auth apis
  userVerify: apiUrl + '/passport/user_verify',
  userSignOut: apiUrl + '/passport/user_signout',
  validateToken: apiUrl + '/passport/validate_token',

  // api
  plansApi: apiUrl + '/v1/paypal_subscription_plans',
  memberStatusApi: apiUrl + '/v1/purchase_memberstatus',
  userRemoveProfile: apiUrl + '/v1/user_remove_profile',
  audioTranscriptionsApi: apiUrl + '/v1/openai_audiotranscriptions',
  audioTranslationsApi: apiUrl + '/v1/openai_audiotranslations',
  chatCompletionsApi: apiUrl + '/v1/openai_chatcompletions',
  aiCreditConsume: apiUrl + '/v1/aicredit_consume',
  aiCreditRetrival: apiUrl + '/v1/aicredit_retrival'
}